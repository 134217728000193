import React from 'react';

import { Link, Spinner } from '@chakra-ui/react';

interface SolidBtnProps {
	content: React.ReactNode;
	href?: string;
	isSubmit?: boolean;
	isEnd?: boolean;
	isCenter?: boolean;
	rounded?: string | { base?: string; sm?: string };
	border?: string | { base?: string; sm?: string };
	width?: string | { base?: string; sm?: string; md?: string };
	variant?: 'solid' | 'outlined' | 'transparent' | 'light';
	onClick?: () => void;
	isLoading?: boolean;
	target?: string;
}

function SolidBtn({
	content,
	href,
	isSubmit = false,
	isEnd = false,
	isCenter = false,
	rounded,
	border,
	width,
	variant = 'solid',
	onClick,
	isLoading,
	target,
}: SolidBtnProps) {
	const commonStyles = {
		// eslint-disable-next-line no-nested-ternary
		alignSelf: isEnd ? 'flex-end' : isCenter ? 'center' : 'flex-start',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		w: width || 'auto',
		h: '53px',
		px: '24px',
		lineHeight: '115%',
		fontFamily: 'gabarito',
		fontSize: '18px',
		fontWeight: 'semibold',
		transition: 'all 0.2s ease',
		borderRadius: rounded || '8px',
		textDecoration: 'none',
		opacity: isLoading ? 0.5 : 1,
	};

	const variantStyles = {
		solid: {
			color: 'neutralLight',
			bg: 'primaryDark.100',
			border: border || '0px',
			boxShadow: '0px 12px 12px -4px hsla(136, 34%, 65%, 0.4)',
			_hover: {
				bg: 'primaryDark.200',
				borderColor: 'primaryDark.200',
			},
		},
		outlined: {
			color: 'neutralLight',
			bg: 'whiteAlpha.300',
			border: border || '1px solid',
			borderColor: 'whiteAlpha.500',
			width: 'fit-content',
			_hover: {
				bg: 'neutralLight',
				borderColor: 'whiteAlpha.500',
				color: 'primaryDark.100',
			},
		},
		transparent: {
			color: 'neutralDark',
			textDecoration: 'underline',
			bg: 'transparent',
			border: '0px',
			_hover: {
				color: 'neutralDarkAlpha',
			},
		},
		light: {
			color: 'primaryDark.100',
			bg: 'primaryDarkAlpha.10',
			fontWeight: '600',
			_hover: {
				bg: 'primaryDarkAlpha.20',
			},
		},
	};

	const selectedStyles = variantStyles[variant];

	return (
		<Link
			onClick={onClick}
			type={isSubmit ? 'submit' : 'button'}
			href={href}
			{...commonStyles}
			{...selectedStyles}
			css={{ pointerEvents: isLoading ? 'none' : 'auto' }}
			target={target}
		>
			{content}
			{isLoading && <Spinner size="sm" />}
		</Link>
	);
}

export default SolidBtn;
