import React, { useState } from 'react';

import { Input, Text } from '@chakra-ui/react';

import SolidBtn from '@/components/landing/buttons/SolidBtn';
import {
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
} from '../ui/dialog';
import { Field } from '../ui/field';

export default function CountPriceModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
	const [count, setCount] = useState('');
	const pricePerEmployee = 497;
	const totalPrice = count ? parseInt(count, 10) * pricePerEmployee : 0;

	return (
		isOpen && (
			<DialogRoot placement="center" lazyMount open={isOpen} onOpenChange={() => onClose()}>
				<DialogContent p={12} maxWidth={446} rounded="2xl" bg="gray.50">
					<DialogHeader mb={8} p={0} borderRadius={4}>
						<DialogTitle
							p={0}
							textAlign="center"
							fontSize="2xl"
							fontFamily="gabarito"
							color="neutralDark"
							fontWeight={500}
						>
							Pro výpočet ceny zadejte počet zaměstnanců ve vaší firmě
						</DialogTitle>
					</DialogHeader>
					<DialogBody p={0} bg="bg.baseMain" textAlign="center" borderRadius={3}>
						<form>
							<Text mb={2} color="gray.500" fontSize="md">
								Počet zaměstnanců{' '}
								<Text as="span" color="red">
									*
								</Text>
							</Text>

							<Field mb={12}>
								<Input
									id="employeeCount"
									textStyle="md"
									fontWeight="400"
									color="neutralDark"
									placeholder="Zadejte počet zaměstnanců"
									value={count}
									onChange={(e) => {
										const value = e.target.value.replace(/\D/g, '');
										setCount(value);
									}}
									px="16px"
									_placeholder={{ color: 'blackAlpha.600' }}
									_focusVisible={{ borderColor: 'primaryDark.100' }}
									bg="white"
									border="1px solid"
									borderColor="gray.600"
									borderRadius="6px"
									css={{
										'&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
											WebkitAppearance: 'none',
											margin: 0,
										},
										appearance: 'textfield',
									}}
								/>
							</Field>

							<Text color="gray.500" fontWeight={500} fontSize="lg">
								{count || 1} × {pricePerEmployee} Kč
							</Text>

							<Text
								m={2}
								fontWeight={500}
								color="primaryDark.100"
								lineHeight="150%"
								fontSize="32px"
								fontFamily="gabarito"
							>
								{totalPrice !== 0 ? totalPrice.toLocaleString('cs-CZ') : pricePerEmployee} Kč
							</Text>

							<Text mb={8} fontWeight={600} color="gray.500" fontSize="md">
								měsíčně za všechny zaměstnance
							</Text>

							<SolidBtn width="full" rounded="lg" content="Pokračovat k platbě" />
						</form>
					</DialogBody>
					<DialogFooter />
					<DialogCloseTrigger />
				</DialogContent>
			</DialogRoot>
		)
	);
}
