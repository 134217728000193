'use client';

import React from 'react';

import { Box, Card, Flex, Grid, Heading, Image, Text, useDisclosure } from '@chakra-ui/react';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';

import SolidBtn from '@/components/landing/buttons/SolidBtn';
import { Button } from '../ui/button';
import CountPriceModal from './CountPriceModal';

export default function PricingTable() {
	const [isMonthly, setIsMonthly] = React.useState(true);
	const { open, onClose } = useDisclosure();

	return (
		<Box id="prices" as="section" py={{ base: '64px', sm: '96px' }} pt={{ base: 0, sm: '96px' }} bg="#F4FCF9">
			<MaxWidthWrapper isFull>
				<Flex
					py={24}
					px={{ base: 0, md: 16 }}
					flexDir="column"
					gap="16"
					justifyContent="center"
					alignItems="center"
					bgImage="url('/images/prices-bg.jpg')"
					bgRepeat="no-repeat"
					bgSize="cover"
					rounded={{ base: 'none', md: '2xl' }}
				>
					<Flex flexDir="column" gap="12" alignItems="center">
						<Heading
							fontWeight={500}
							color="neutralDark"
							fontSize={{ base: '32px', sm: '48px' }}
							lineHeight="120%"
							textAlign="center"
							css={{
								textWrap: 'balance',
							}}
							as="h2"
						>
							Změna na&nbsp;dosah ruky. <Text color="primaryDark.100">Stačí jen udělat první krok.</Text>
						</Heading>

						<Flex bg="white" p={3} rounded="xl">
							<Button
								onClick={() => setIsMonthly(false)}
								bg={isMonthly ? 'transparent' : 'primaryDark.100'}
								w={{ base: '130px', sm: '180px' }}
								rounded="lg"
								display="flex"
								gap={2.5}
								color={isMonthly ? 'neutralDark' : 'white'}
							>
								Ročně{' '}
								<Box
									px={2}
									py={1}
									bg={isMonthly ? 'primaryDarkAlpha.10' : 'whiteAlpha.300'}
									rounded="2xl"
									color={isMonthly ? 'primaryDark.100' : 'white'}
								>
									-15 %
								</Box>
							</Button>
							<Button
								w={{ base: '130px', sm: '180px' }}
								rounded="lg"
								bg={isMonthly ? 'primaryDark.100' : 'transparent'}
								color={isMonthly ? 'neutralLight' : 'neutralDark'}
								onClick={() => setIsMonthly(true)}
							>
								Měsíčně
							</Button>
						</Flex>
					</Flex>

					<Grid
						w={{ base: 'calc(100% - 12vw)', md: 'full' }}
						templateColumns={{ base: '1fr', xl: '1fr 1fr 1fr' }}
						alignItems="center"
						gap={{ base: 8, '2xl': 16 }}
					>
						<Card.Root
							p={{ base: 12 }}
							w="full"
							h="fit-content"
							textAlign="center"
							justifyContent="center"
							display="flex"
							alignItems="center"
							borderColor="blackAlpha.100"
							rounded="2xl"
							shadow="0px 10px 10px -5px #0000000A"
						>
							<Text mb={3} color="neutralDark" fontWeight="500" fontFamily="gabarito" fontSize="xl">
								Zkušební na 14 dní
							</Text>
							<Text mb={8} color="primaryDark.100" fontSize="40px" fontFamily="gabarito" fontWeight="500">
								Zdarma
							</Text>

							<SolidBtn
								content="Vyzkoušet ZDARMA"
								width={{ base: 'full', sm: 'fit-content' }}
								href="/login"
								variant="light"
								isCenter
							/>
							{open && <CountPriceModal isOpen={open} onClose={onClose} />}
						</Card.Root>

						<Card.Root
							p={12}
							w="full"
							textAlign="center"
							h="fit-content"
							borderColor="blackAlpha.100"
							justifyContent="center"
							display="flex"
							alignItems="center"
							rounded="2xl"
							shadow="0px 10px 10px -5px #0000000A"
						>
							<Flex
								w="fit-content"
								fontWeight={500}
								mb={5}
								gap={2}
								px="3"
								py={2}
								bg="secondaryAlpha.10"
								rounded="full"
								alignItems="center"
							>
								<Image src="/images/hero_section_dashboard-bg.svg" alt="check" w={6} h="26px" />{' '}
								<Text fontFamily="gabarito" fontSize="20px" color="neutralDark">
									Premium
								</Text>
							</Flex>
							<Text mb={2} color="primaryDark.100" fontSize="40px" fontWeight="500" fontFamily="gabarito">
								{isMonthly ? '497' : '5069'} Kč
							</Text>
							<Text mb={8} color="gray.500" fontSize="base" fontWeight="600">
								{isMonthly ? 'měsíčně za jednoho zaměstnance' : 'ročně za jednoho zaměstnance'}
							</Text>

							<SolidBtn width="full" rounded="lg" href="/login" content="Začít" />
						</Card.Root>

						<Card.Root
							p={12}
							w="full"
							h="fit-content"
							textAlign="center"
							borderColor="blackAlpha.100"
							justifyContent="center"
							display="flex"
							alignItems="center"
							rounded="2xl"
							shadow="0px 10px 10px -5px #0000000A"
						>
							<Text mb={3} color="neutralDark" fontWeight="500" fontFamily="gabarito" fontSize="xl">
								Enterprise
							</Text>
							<Text mb={8} color="primaryDark.100" fontSize="40px" fontFamily="gabarito" fontWeight="500">
								Individuální nabídka
							</Text>

							<SolidBtn
								content="Naplánovat schůzku"
								width={{ base: '100%', sm: 'fit-content' }}
								href="https://calendly.com/filip-macoun-taskgrip/taskgrip"
								target="_blank"
								isCenter
							/>
						</Card.Root>
					</Grid>
				</Flex>
			</MaxWidthWrapper>
		</Box>
	);
}
